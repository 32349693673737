import React, { useState } from 'react';
import {
  collection,
  doc,
  getFirestore,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import Header from '../Components/Header';
import { Team } from '../Recoil/types';
import Button from '../Components/Button';
import styled from 'styled-components';
import { db } from '../firebase';
import { useRecoilValue } from 'recoil';
import { raceDataState } from '../Recoil/selectors';
import Status from './Status';
import {
  GroupWork,
  LocationOn,
  People,
  Timer,
  WorkOutline,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ErrorText from '../Components/ErrorText';

const createTeams = async (
  raceId: string,
  participants: string[],
  teamSize: number,
) => {
  const numParticipants = participants.length;

  let numTeams = Math.floor(numParticipants / teamSize);
  let baseTeamSize = Math.floor(numParticipants / numTeams);
  let remaining = numParticipants - baseTeamSize * numTeams;

  for (let i = 0, j = 0; i < numParticipants; j++) {
    let teamSize = baseTeamSize + (remaining-- > 0 ? 1 : 0);

    // Create a new team object
    let team: Team = {
      raceId: raceId,
      id: `${j + 1}`,
      members: [],
      name: `Team ${j + 1}`,
      completedTasks: [],
      points: 0,
    };

    for (let k = 0; k < teamSize; k++) {
      team.members.push(participants[i]); // Add participant to team members
      i++;
    }

    // Add the team to the race's subcollection
    const db = getFirestore();
    await setDoc(
      doc(collection(db, `races/${raceId}/teams`), `${j + 1}`),
      team,
    );
  }
};

const RaceContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.black};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.offWhite};
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.darkPurple};
  margin-bottom: 30px;
  padding: 20px 30px;
  background-color: ${({ theme }) => theme.colors.offWhite};
  box-shadow: 2px 2px 2px #888888;
  position: relative;
  max-width: 560px;
  width: 80%;

  &:hover {
    box-shadow: 3px 3px 3px #888888;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    max-width: 300px;
    margin-bottom: 25px;
  }
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const RaceHeader = styled(Header)`
  color: ${({ theme }) => theme.colors.teal};
  text-shadow: 1px 1px ${({ theme }) => theme.colors.pink};
`;

const RaceParagraph = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.darkPurple};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 600;
  letter-spacing: 0.5px;
`;

const RaceButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.brightYellow};
  color: ${({ theme }) => theme.colors.darkPurple};
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightYellow};
  }
`;

const Race = ({ gamePin }: { gamePin: string }) => {
  const raceData = useRecoilValue(raceDataState(gamePin));
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);


  const { t } = useTranslation();

  const handleStart = async () => {
    if (!raceData) return;
    const raceRef = doc(db, 'races', raceData.id);

    if (raceData.participants.length < raceData.teamSize){
      setErrorMessage("Det må være nok deltagere til å fylle minst et lag for å kunne starte spillet.")
      return;
    }

    await createTeams(raceData.id, raceData.participants, raceData?.teamSize);

    await updateDoc(raceRef, {
      status: 'ongoing',
      startedAt: serverTimestamp(),
    });
  };

  const handleEdit = () => {
    navigate(`/edit-race/${raceData?.id}`);
  };

  if (!raceData) return <div>Loading...</div>;

  return (
    <RaceContainer>
      <IconButton onClick={handleEdit} 
      sx={{
        position: 'absolute',
        top: '0.5rem',
        right: '0.5rem',
        color: '#301934' 
      }}>
        <Edit />
      </IconButton>
      <RaceHeader level={3}>{raceData.name}</RaceHeader>
      <Status status={raceData.status} />
      <InfoRow>
        <LocationOn />
        <RaceParagraph>Game pin: {gamePin}</RaceParagraph>
      </InfoRow>
      <InfoRow>
        <WorkOutline />
        <RaceParagraph>
          {' '}
          {`${t('raceData.package')}: ${raceData.package}`}
        </RaceParagraph>
      </InfoRow>
      <InfoRow>
        <Timer />
        <RaceParagraph>{`${t('raceData.duration')}: ${raceData.duration} ${t(
          'general.minutes',
        )}`}</RaceParagraph>
      </InfoRow>
      <InfoRow>
        <People />
        <RaceParagraph>{`${t('raceData.teamSize')}: ${
          raceData.teamSize
        }`}</RaceParagraph>
      </InfoRow>
      <InfoRow>
        <GroupWork />
        <RaceParagraph>
          {`${t('raceData.participants')}: ${raceData.participants.length}`}
        </RaceParagraph>
      </InfoRow>
      {raceData.status === 'pending' && (
          <RaceButton onClick={handleStart}>Start Race</RaceButton>
        )}
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </RaceContainer>
  );
};

export default Race;
