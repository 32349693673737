import styled from 'styled-components';
import { theme } from '../theme';

interface ParagraphProps {
  children: React.ReactNode;
}

const StyledParagraph = styled.p`
  font-family: ${theme.fonts.secondary};
  color: ${theme.colors.darkPurple};
  font-size: 1em;
  margin-bottom: 2em;
`;

const Paragraph = ({ children }: ParagraphProps) => {
  return <StyledParagraph>{children}</StyledParagraph>;
};

export default Paragraph;
