import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Team } from '../Contexts/TeamProvider';
import { raceDataState } from '../Recoil/selectors';
import { useRecoilValue } from 'recoil';
import { collection, doc, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useParams } from 'react-router-dom';

const LeaderboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 99%;
  max-width: 400px;
`;

const LeaderboardTitle = styled.h1`
  color: #ff6b6b;
  font-family: Bangers, cursive;
`;

const TeamContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 2px solid #000000;
  border-radius: 10px;
  margin: 10px 10px;
  padding: 10px;
  background-color: #f8f8f2;
  box-shadow: 2px 2px 2px #888888;
  position: relative;
  width: 90%;

  &:hover {
    box-shadow: 3px 3px 3px #888888;
  }
`;

const TeamName = styled.span`
  font-family: Bangers, cursive;
  font-size: 1.2em;
  color: #222;
`;

const TeamPoints = styled.span`
  font-family: Bangers, cursive;
  font-size: 1.2em;
  color: #00cc00;
`;

const Leaderboard = () => {
  const [teams, setTeams] = useState<Team[]>([]);
  const { gamePin } = useParams();
  const raceData = useRecoilValue(raceDataState(gamePin ?? ""));

  useEffect(() => {
    if (!raceData) return;

    const fetchTeams = async () => {
      try {
        const teamCollection = collection(doc(db, 'races', raceData.id), 'teams');
        const teamSnapshot = await getDocs(teamCollection);
        const teamsData: Team[] = teamSnapshot.docs.map(doc => doc.data() as Team);
        teamsData.sort((teamA, teamB) => teamB.points - teamA.points);
        setTeams(teamsData);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };

    fetchTeams();
  }, [raceData]);

  return (
    <LeaderboardContainer>
      <LeaderboardTitle>Leaderboard</LeaderboardTitle>
      {teams.map((team, index) => (
        <TeamContainer key={index}>
          <TeamName>{team.name}</TeamName>
          <TeamPoints>{team.points}</TeamPoints>
        </TeamContainer>
      ))}
    </LeaderboardContainer>
  );
};

export default Leaderboard;

