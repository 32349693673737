import styled from 'styled-components';
import { Status } from '../Recoil/types';
import { useTranslation } from 'react-i18next';

const StatusLabelStyle = styled.div<{ status: Status }>`
  color: ${({ theme }) => theme.colors.darkPurple};
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: bold;
  margin-bottom: 30px;
  width: fit-content;
  background-color: ${({ status, theme }) => {
    switch (status) {
      case 'pending':
        return theme.colors.lightYellow;
      case 'ongoing':
        return theme.colors.teal;
      case 'completed':
        return theme.colors.lightPurple;
      default:
        return theme.colors.offWhite;
    }
  }};
`;

const StatusLabel = ({ status }: { status: Status }) => {
  const { t } = useTranslation();
  const formatStatus = (status: Status) => {
    switch (status) {
      case 'pending':
        return t('status.pending');
      case 'ongoing':
        return t('status.ongoing');
      case 'completed':
        return t('status.completed');
      default:
        return 'Unknown';
    }
  };
  return (
    <StatusLabelStyle status={status}>{formatStatus(status)}</StatusLabelStyle>
  );
};

export default StatusLabel;
