import { useEffect, useState } from 'react';
import FeedEntry from './FeedEntry';

import { addDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useRecoilValue } from 'recoil';
import { raceDataState } from '../Recoil/selectors';
import { authState } from '../Recoil/atoms';
import { useParams } from 'react-router-dom';

export type Media = {
  id: string;
  url: string;
  teamId: string;
  date: number;
  description: string;
  name: string;
  type: string;
  likesCount: number;
  comments: FeedComment[];
};

export type FeedComment = {
  text: string;
  userId: string;
  timestamp: number;
  userName: string;
};

const Feed = () => {
  const [mediaEntries, setMediaEntries] = useState<Media[]>([]);
  const { gamePin } = useParams();
  const raceData = useRecoilValue(raceDataState(gamePin ?? ""));
  const auth = useRecoilValue(authState);

  useEffect(() => {
    if (!raceData?.id) return;

    const fetchMediaEntries = async () => {
      try {
        const tasksQuerySnapshot = await getDocs(collection(db, `races/${raceData.id}/tasks`));
        let newMediaEntries: Media[] = [];

        for (const taskDoc of tasksQuerySnapshot.docs) {
          const taskData = taskDoc.data();
          for (const proof of taskData.proofs) {
            const likesSnapshot = await getDocs(collection(db, `races/${raceData.id}/tasks/${taskDoc.id}/likes`));
            const commentsSnapshot = await getDocs(collection(db, `races/${raceData.id}/tasks/${taskDoc.id}/comments`));

            const newEntry: Media = {
              id: taskDoc.id,
              url: proof.url,
              teamId: proof.teamId,
              date: proof.timestamp.toMillis(),
              description: taskData.description,
              name: taskData.title,
              type: 'MEDIA',
              likesCount: likesSnapshot.docs.length,
              comments: commentsSnapshot.docs.map(doc => ({
                ...doc.data(),
                timestamp: doc.data().timestamp,
                userId: doc.data().userId,
              } as FeedComment)),
            };
            newMediaEntries.push(newEntry);
          }
        }
        setMediaEntries(newMediaEntries);
      } catch (error) {
        console.error("Error fetching media entries:", error);
      }
    };

    fetchMediaEntries();
  }, [raceData]);

  const likePost = async (postId: string) => {
    try {
    if (!raceData?.id) return;
      const likesCollectionRef = collection(db, `races/${raceData.id}/tasks/${postId}/likes`);
      const newLike = {
        userId: auth.uid,
        timestamp: Date.now(),
      };
      await addDoc(likesCollectionRef, newLike);
    } catch (error) {
      console.error('Error adding like:', error);
    }
  };

  const addComment = async (postId: string, comment: string, userName: string) => {
    try {
    if (!raceData?.id) return;
      const commentsCollectionRef = collection(db, `races/${raceData.id}/tasks/${postId}/comments`);
      const newComment: FeedComment = {
        userId: auth.uid!,
        text: comment,
        timestamp: Date.now(),
        userName,
      };
      await addDoc(commentsCollectionRef, newComment);
      setMediaEntries(prevEntries =>
        prevEntries.map(entry =>
          entry.id === postId
            ? { ...entry, comments: [...entry.comments, newComment] }
            : entry
        )
      );
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  return (
    <div style={{ maxWidth: '600px' }}>
      {mediaEntries.sort((a, b) => b.date - a.date).map(entry => (
        <FeedEntry
          key={entry.id}
          entry={entry}
          onComment={addComment}
          onLike={likePost}
          likes={entry.likesCount}
          comments={entry.comments}
        />
      ))}
    </div>
  );
};

export default Feed;
