import React from 'react';
import styled from 'styled-components';
import SignIn from './Components/SignIn';
import { useCurrentUser } from './Contexts/UserProvider';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

interface ToggleButtonProps {
  active?: boolean;
}

const ToggleButton = styled.button<ToggleButtonProps>`
  margin: 0 12px;
  color: #301934;
  border: none;
  border-bottom: ${(props) => (props.active ? '2px solid #301934' : 'none')};
  cursor: pointer;
  background-color: #fab005;
  font-size: 1em;
  font-family: Bangers, cursive;
  padding: 0;
`;

const HeaderContainer = styled.header`
  display: flex;
  width: 100%;
  height: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: ${({ theme }) =>
    theme.colors.brightYellow}; /* Bright yellow for comic-style retro look */
  color: ${({ theme }) =>
    theme.colors.darkPurple}; /* Switched color scheme for contrast */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  border: 2px solid ${({ theme }) => theme.colors.darkPurple}; /* Border for a bold comic look */
  margin-bottom: 20px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Title = styled.h1`
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: 600;
  color: #301934;
  font-family: Bangers, cursive; /* Updated font */
  @media (max-width: 768px) {
    font-size: 1.5em;
    margin-bottom: 15px;
  }
`;

const ToggleView = () => {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const { gamePin } = useParams();

  const handleButtonClick = (route: string) => {
    navigate(`/race/${gamePin}/${route}`);
  };

  return (
    <>
      <HeaderContainer>
        <Title>Remarkable Race</Title>
        <ButtonContainer>
          <ToggleButton
            active={location.pathname.includes('tasks')}
            onClick={() => handleButtonClick('tasks')}
          >
            Tasks
          </ToggleButton>
          <ToggleButton
            active={location.pathname.includes('feed')}
            onClick={() => handleButtonClick('feed')}
          >
            Feed
          </ToggleButton>
          <ToggleButton
            active={location.pathname.includes('leaderboard')}
            onClick={() => handleButtonClick('leaderboard')}
          >
            Leaderboard
          </ToggleButton>
        </ButtonContainer>
      </HeaderContainer>
      {!user && <SignIn />}
      <Outlet />
    </>
  );
};

export default ToggleView;
