import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

interface VideoProps {
  src: string;
}

const StyledVideo = styled.video`
  width: 100%;
`;
const Video = ({ src }: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          videoRef.current?.load();
        } else {
          videoRef.current?.pause();
        }
      },
      { threshold: 0.1 },
    );
    if (videoRef.current) observer.observe(videoRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <StyledVideo ref={videoRef} controls>
      <source data-src={src} type="video/mp4" />
    </StyledVideo>
  );
};

export default Video;
