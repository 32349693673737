import React from 'react';
import styled from 'styled-components';

const StyledLinkButton = styled.button`
  background: none;
  color: ${({ theme }) => theme.colors.darkPurple};
  border: none;
  border-bottom: 2px solid ${({ theme }) => theme.colors.darkPurple};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1em;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin: 10px 0;
  padding: 0;

  &:hover {
    border-bottom: 2px solid ${({ theme }) => theme.colors.pink};
  }

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

interface LinkButtonProps {
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const LinkButton = ({
  children,
  type = 'button',
  onClick,
}: LinkButtonProps) => {
  return (
    <StyledLinkButton type={type} onClick={onClick}>
      {children}
    </StyledLinkButton>
  );
};

export default LinkButton;
