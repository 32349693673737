import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FeedComment, Media } from './Feed';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Header from '../Components/Header';
import InteractionBar from './InteractionBar';
import Video from './Video';
import { useRecoilValue } from 'recoil';
import { authState } from '../Recoil/atoms';
import { getDoc, getFirestore, doc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const fileIsVideoFormat = (mediaUrl: string): boolean => {
  const videoFormats = ['.mov', '.mp4', '.avi'];
  return videoFormats.some((format) =>
    mediaUrl.toLocaleLowerCase().includes(format),
  );
};

const Info = styled.div`
  display: flex;
  flex-direction: column;
  color: #301934;
  padding: 1rem 1rem 0rem 1rem;
  margin-bottom: 1rem;
  font-family: Bangers, cursive;

  p {
    padding: 0;
    margin: 0;
    font-size: 14px;
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
`;

const StyledLazyImage = styled(LazyLoadImage)`
  width: 100%;
`;

const MediaCard = styled.div`
  border-radius: 10px;
  margin: 20px 10px;
  padding: 10px;
  background-color: #f8f8f2;
  box-shadow: 2px 2px 2px #888888;
  border: 2px solid #000000;
  max-width: 600px;
`;

const TextCard = styled.div`
  background: #f8f8f2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

interface FeedEntryProps {
  entry: Media;
  onLike: (postId: string) => void;
  onComment: (postId: string, comment: string, userName: string) => void;
  likes: number;
  comments: FeedComment[];
}

const FeedEntry = ({
  entry,
  onLike,
  onComment,
  likes,
  comments,
}: FeedEntryProps) => {
  const [localLikes, setLocalLikes] = useState(likes);
  const [localComments, setLocalComments] = useState(comments);
  const auth = useRecoilValue(authState);

  useEffect(() => {
    setLocalLikes(likes);
  }, [likes]);

  useEffect(() => {
    setLocalComments(comments);
  }, [comments]);

  const handleLike = () => {
    onLike(entry.id);
    setLocalLikes((prevLikes) => prevLikes + 1);
  };

  const handleComment = async (comment: string) => {
    const authFirebase = getAuth();

    const db = getFirestore();
    const userDocRef = doc(db, 'users', authFirebase.currentUser?.uid ?? "");
    const userDoc = await getDoc(userDocRef);
    const userName = userDoc.data()?.userName || 'Anonymous';

    onComment(entry.id, comment, userName);
    const newComment: FeedComment = {
      userId: authFirebase.currentUser?.uid ?? '',
      text: comment,
      timestamp: Date.now(),
      userName,
    };
    setLocalComments((prevComments) => [...prevComments, newComment]);
  };

  if (entry.type === 'MEDIA') {
    const isMovie: boolean =
      entry?.url !== null &&
      entry?.url !== undefined &&
      fileIsVideoFormat(entry.url);

    return (
      <MediaCard>
        <Info>
          <Header level={4}>{entry.name}</Header>
          <p>{'Team ' + entry.teamId}</p>
        </Info>
        {!isMovie && (
          <StyledLazyImage src={entry.url} alt="bilde her" effect="blur" />
        )}
        {isMovie && <Video src={entry.url} />}
        <InteractionBar
          onLike={handleLike}
          onComment={handleComment}
          likesCount={localLikes}
          comments={localComments}
        />
      </MediaCard>
    );
  } else {
    return (
      <TextCard style={{ backgroundColor: '#fffaf1' }}>
        <Info style={{ color: 'black' }}>
          <p>{`${entry.teamId} ${entry.description}`}</p>
          <p>{entry.date}</p>
        </Info>
      </TextCard>
    );
  }
};

export default FeedEntry;