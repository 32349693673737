import React from 'react';
import styled from 'styled-components';
import Paragraph from '../../Components/Paragraph';
import Label from '../../Components/Label';

const PackageWrapper = styled.div<{ isSelected: boolean }>`
  border: 2px solid #000000;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 20px 10px;
  background-color: ${({ theme }) => theme.colors.offWhite};
  box-shadow: 2px 2px 2px #888888;
  position: relative;
  max-width: 580px;

  &:hover {
    box-shadow: 3px 3px 3px #888888;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    max-width: 230px;
    margin-bottom: 25px;
  }
`;

const Package = ({
  name,
  selected,
  onSelect,
  description,
}: {
  name: string;
  selected: string;
  description: string;
  onSelect: (name: string) => void;
}) => {
  const isSelected = name === selected;

  return (
    <PackageWrapper isSelected={isSelected}>
      <Label>
        <input
          type="radio"
          name="package"
          value={name}
          checked={isSelected}
          onChange={() => onSelect(name)}
        />
        <span>{name}</span>
        <Paragraph>{description}</Paragraph>
      </Label>
    </PackageWrapper>
  );
};

export default Package;
