import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  background: ${({ theme }) => theme.colors.teal};
  color: ${({ theme }) => theme.colors.offWhite};
  border: 2px solid ${({ theme }) => theme.colors.darkPurple};
  border-radius: 4px;
  font-family: Bangers, cursive;
  font-size: 1em;
  padding: 10px 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin: 5px;

  &:hover {
    background: ${({ theme }) => theme.colors.pink};
  }

  @media (max-width: 768px) {
    padding: 5px 10px;
    font-size: 0.8em;
  }
`;

interface ButtonProps {
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

const Button = ({
  children,
  type = 'button',
  onClick,
  disabled,
}: ButtonProps) => {
  return (
    <StyledButton type={type} onClick={onClick} disabled={disabled}>
      {children}
    </StyledButton>
  );
};

export default Button;
