import styled from 'styled-components';

interface InputProps {
  type: string;
  value: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StyledInput = styled.input`
  font-family: Bangers, cursive;
  color: ${({ theme }) => theme.colors.darkPurple};
  background-color: ${({ theme }) => theme.colors.offWhite};
  border: 2px solid ${({ theme }) => theme.colors.darkPurple};
  padding: 10px 5px;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  max-width: 380px;
  margin-bottom: 30px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    max-width: 230px;
    margin-bottom: 25px;
  }
`;

const InputField = ({ value, placeholder, onChange, type }: InputProps) => {
  return (
    <StyledInput
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      type={type}
    />
  );
};

export default InputField;
