import styled from 'styled-components';

interface HeaderProps {
  children: React.ReactNode;
}

const StyledHeader1 = styled.h1`
  font-family: Bangers, cursive;

  color: ${({ theme }) => theme.colors.darkPurple};
  font-size: 2.5em;
  textAlign: 'center';
  white-space: nowrap;
`;

const StyledHeader2 = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.darkPurple};
  font-size: 2em;
`;

const StyledHeader3 = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.darkPurple};
  font-size: 1.75em;
`;

const StyledHeader4 = styled.h4`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.darkPurple};
  font-size: 1.5em;
`;

const StyledHeader5 = styled.h5`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.darkPurple};
  font-size: 1.25em;
`;

const StyledHeader6 = styled.h6`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.darkPurple};
  font-size: 1em;
  margin: 0.5rem;
`;

interface HeaderPropsWithLevel extends HeaderProps {
  level: 1 | 2 | 3 | 4 | 5 | 6;
}

const Header = ({ level, children }: HeaderPropsWithLevel) => {
  if (level === 1) return <StyledHeader1>{children}</StyledHeader1>;
  if (level === 2) return <StyledHeader2>{children}</StyledHeader2>;
  if (level === 3) return <StyledHeader3>{children}</StyledHeader3>;
  if (level === 4) return <StyledHeader4>{children}</StyledHeader4>;
  if (level === 5) return <StyledHeader5>{children}</StyledHeader5>;
  if (level === 6) return <StyledHeader6>{children}</StyledHeader6>;

  return null; // or a default header...
};

export default Header;
