import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { doc, getFirestore, updateDoc, collection, getDocs, getDoc, writeBatch, deleteDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../Components/Button';
import ErrorText from '../../Components/ErrorText';
import { FlexContainer } from '../../Components/FlexContainer';
import Header from '../../Components/Header';
import { RaceData } from '../../Recoil/types';
import { TaskData } from '../../TaskView/Task';
import Label from '../../Components/Label';
import styled from 'styled-components';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';

const TaskWrapper = styled.div`
  border: 2px solid #000000;
  border-radius: 10px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 10px;
  background-color: ${({ theme }) => theme.colors.offWhite};
  box-shadow: 2px 2px 2px #888888;
  position: relative;
  max-width: 600px;

  &:hover {
    box-shadow: 3px 3px 3px #888888;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-bottom: 25px;
  }
`;

const InputField = styled.input`
  color: ${({ theme }) => theme.colors.darkPurple};
  border: none;
  background-color: ${({ theme }) => theme.colors.offWhite};
  padding: 10px 5px;
  border-radius: 5px;
  font-size: 14px;
  width: 95%;
  max-width: 380px;
  margin-bottom: 30px;
  height: fit-content;
  font-family: ${({ theme }) => theme.fonts.secondary};

  -moz-appearance: textfield;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    max-width: 320px;
    margin-bottom: 25px;
  }

  &:focus {
    box-shadow: 3px 3px 3px #888888;
    border: 2px solid ${({ theme }) => theme.colors.darkPurple};
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;


const StyledForm = styled.form`
  width: 90%;
`;

const DescriptionTextarea = styled.textarea`
  color: ${({ theme }) => theme.colors.darkPurple};
  border: none;
  background-color: ${({ theme }) => theme.colors.offWhite};
  padding: 10px 5px;
  border-radius: 5px;
  font-size: 14px;
  width: 95%;
  max-width: 380px;
  margin-bottom: 5px;
  height: fit-content;
  resize: vertical;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    max-width: 320px;
    margin-bottom: 25px;
  }

  &:focus {
    box-shadow: 3px 3px 3px #888888;
    border: 2px solid ${({ theme }) => theme.colors.darkPurple};
  }
`;

const EditRace = () => {
  const { raceId } = useParams<{ raceId: string }>();
  const [tasks, setTasks] = useState<TaskData[]>([]);
  const [raceData, setRaceData] = useState<RaceData | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!raceId) {
      setErrorMessage('Race ID is not defined');
      return;
    }

    const fetchRaceData = async () => {
      const db = getFirestore();
      const raceDoc = await getDoc(doc(db, 'races', raceId));
      if (!raceDoc.exists()) {
        setErrorMessage('Race not found');
        return;
      }
      const tasksCollection = collection(db, 'races', raceId, 'tasks');
      const tasksSnapshot = await getDocs(tasksCollection);

      const tasksList = tasksSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: Number(doc.id),
      })) as TaskData[];

      setRaceData(raceDoc.data() as RaceData);
      setTasks(tasksList);
    };

    fetchRaceData();
  }, [raceId]);

  const handleTaskChange = (index: number, field: string, value: string | number) => {
    const newTasks = [...tasks];
    newTasks[index] = {
      ...newTasks[index],
      [field]: value,
    };
    setTasks(newTasks);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!raceId) {
      setErrorMessage('Race ID is not defined');
      return;
    }
    const db = getFirestore();

    try {
      const batch = writeBatch(db);

      tasks.forEach((task) => {
        const taskRef = doc(db, 'races', raceId, 'tasks', task.id.toString());
        batch.update(taskRef, task);
      });

      await batch.commit();
      navigate('/my-page');
    } catch (e) {
      console.error('Error updating tasks: ', e);
      setErrorMessage('Error updating tasks');
    }
  };

  const handleDeleteTask = async (taskId: number) => {
    if (!raceId) {
      setErrorMessage('Race ID is not defined');
      return;
    }
    const db = getFirestore();
    try {
      await deleteDoc(doc(db, 'races', raceId, 'tasks', taskId.toString()));
      setTasks(tasks.filter(task => task.id !== taskId));
    } catch (e) {
      console.error('Error deleting task: ', e);
      setErrorMessage('Error deleting task');
    }
  };

  const handleAddTask = () => {
    const newTaskId = tasks.length > 0 ? tasks[tasks.length - 1].id + 1 : 1;
    const newTask = {
      id: newTaskId,
      title: '',
      description: '',
      points: 0,
    };
    setTasks([...tasks, newTask]);
  };

  if (!raceId) {
    return <div>Invalid race ID</div>;
  }

  return (
    <>
      <Header level={2}>Endre oppgaver</Header>
      <StyledForm onSubmit={handleSubmit}>
        {tasks.map((task, index) => (
          <TaskWrapper key={task.id}>
            <IconButton onClick={() => handleDeleteTask(task.id)} 
              sx={{
                position: 'absolute',
                top: '0.5rem',
                right: '0.5rem',
                color: '#301934' 
              }}>
                <DeleteIcon />
            </IconButton>
            <FlexContainer flexDirection={'column'} alignItems={'flex-start'}>
              <Label>Navn</Label>
              <InputField
                type="text"
                value={task.title}
                onChange={(e) => handleTaskChange(index, 'title', e.target.value)}
              />
              <Label>Beskrivelse</Label>
              <DescriptionTextarea
                value={task.description}
                onChange={(e) => handleTaskChange(index, 'description', e.target.value)}
              />
              <Label>Poeng</Label>
              <InputField
                type="number"
                value={task.points || ''}
                onChange={(e) => handleTaskChange(index, 'points', Number(e.target.value))}
              />
            </FlexContainer>
          </TaskWrapper>
        ))}
        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        <FlexContainer>
        <Button onClick={handleAddTask}>
         Ny oppgave
        </Button>
        <Button type="submit">Lagre</Button>
        </FlexContainer>
      </StyledForm>
    </>
  );
};

export default EditRace;
