import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
  font-family: Bangers, cursive;
  color: ${({ theme }) => theme.colors.darkPurple};
  font-size: 1.2em;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 1em;
  }
`;

interface LabelProps {
  children: React.ReactNode;
  htmlFor?: string;
}

const Label = ({ children, htmlFor }: LabelProps): JSX.Element => {
  return <StyledLabel htmlFor={htmlFor}>{children}</StyledLabel>;
};

export default Label;
