export const theme = {
  colors: {
    lightPurple: '#A56CC1',
    pink: '#FF6B6B',
    teal: '#1FCE6D',
    lightYellow: '#FFFA8F',
    offWhite: '#F8F8F2',
    brightYellow: '#fab005',
    darkPurple: '#301934',
    black: '#000000',
  },
  breakpoints: {
    mobile: '768px',
    tablet: '1024px',
    desktop: '1200px',
  },
  fonts: {
    primary: 'Bangers, cursive',
    secondary: 'monospace',
  },
};
