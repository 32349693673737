import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import Header from '../Components/Header';
import Paragraph from '../Components/Paragraph';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import SignIn from '../Components/SignIn';
import { UserContext } from '../Contexts/UserProvider';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authState } from '../Recoil/atoms';
import { raceDataState } from '../Recoil/selectors';
import { RaceData } from '../Recoil/types';

const WaitingRoomContainer = styled.div`
  border: 4px solid ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.brightYellow};
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.darkPurple};
  margin: 50px auto;
  padding: 20px 30px;
  box-shadow: 2px 2px 2px #888888;
  position: relative;
  max-width: 560px;
  width: 100%;

  &:hover {
    box-shadow: 3px 3px 3px #888888;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    max-width: 70%;
  }
`;

const WaitingRoom = () => {
  const { gamePin } = useParams();
  const [raceId, setRaceId] = useState<string | null>(null);
  const [participants, setParticipants] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const user = useContext(UserContext);
  const raceData = useRecoilValue<RaceData | null>(raceDataState(gamePin!));
  const authFirebase = getAuth();
  const setAuth = useSetRecoilState(authState);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authFirebase, async (currentUser) => {
      if (currentUser) {
        setAuth({ uid: currentUser.uid });
      }
    });

    return () => unsubscribe();
  }, [authFirebase, setAuth]);

  useEffect(() => {
    if (!user) return;

    const participant = localStorage.getItem('userName');
    const db = getFirestore();

    const updateUserData = async () => {
      if (participant && raceData) {
        const userDocRef = doc(db, 'users', user.uid);
        await setDoc(userDocRef, {
          uid: user.uid,
          userName: participant,
          gamePin: gamePin,
          team: '',
          completedTasks: [],
          points: 0,
        });

        await updateDoc(doc(db, 'races', raceData.id), {
          participants: [...raceData.participants, user.uid],
        });
      }
    };

    updateUserData();
  }, [user, gamePin, raceData]);

  useEffect(() => {
    const fetchRaceId = async () => {
      const db = getFirestore();
      const racesRef = collection(db, 'races');
      const q = query(racesRef, where('gamePin', '==', gamePin));

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const raceId = querySnapshot.docs[0].id;
        setRaceId(raceId);
      }
    };

    fetchRaceId();
  }, [gamePin]);

  useEffect(() => {
    if (!raceId) {
      return;
    }

    const db = getFirestore();
    const unsubscribeRace = onSnapshot(doc(db, 'races', raceId), (doc) => {
      const data = doc.data();
      if (data?.status === 'ongoing') {
        navigate(`/race/${gamePin}/tasks`);
      }
      if (data?.startDate) {
        setStartDate(data.startDate.toDate());
      }
    });

    const unsubscribeParticipants = onSnapshot(
      collection(db, 'races', raceId, 'participants'),
      (snapshot) => {
        const participantsList = snapshot.docs.map((doc) => doc.data());
        setParticipants(participantsList);
      },
    );

    return () => {
      unsubscribeRace();
      unsubscribeParticipants();
    };
  }, [raceId, gamePin, navigate]);

  if (!user) {
    return <SignIn text={"Log in for å få full tilgang til spillet."}/>;
  }

  return (
    <WaitingRoomContainer>
      <Header level={1}>{t('waitingRoom.title')}</Header>
      <Paragraph>{t('waitingRoom.description')}</Paragraph>
    </WaitingRoomContainer>
  );
};

export default WaitingRoom;


