import React, { useContext, useEffect, useState } from 'react';
import Race from './Race';
import { db } from '../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import SignIn from '../Components/SignIn';
import { UserContext } from '../Contexts/UserProvider';
import Header from '../Components/Header';
import { useNavigate } from 'react-router-dom';
import Button from '../Components/Button';
import { useTranslation } from 'react-i18next';
import { FlexContainer } from '../Components/FlexContainer';
import { RaceData } from '../Recoil/types';

const MyPage = () => {
  const [races, setRaces] = useState<RaceData[]>([]);
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchRaces = async () => {
      if (!user) return;

      const racesCollection = collection(db, 'races');
      const q = query(racesCollection, where('createdBy', '==', user.uid));
      const raceSnapshot = await getDocs(q);

      const raceList = raceSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as RaceData[];

      setRaces(raceList);

      if (raceList.length === 0) {
        navigate('/create');
      }
    };

    fetchRaces();
  }, [navigate, user]);

  if (!user) {
    return <SignIn  text={"Log in for å kunne lage og se races."}/>;
  }

  return (
    <FlexContainer width={'100%'}>
      <Header level={1}>{t('myPage.title')}</Header>
      {races.map((race) => (
        <Race key={race.id} {...race} />
      ))}

      <Button onClick={() => navigate(`/create`)}>
        {t('myPage.create')}
      </Button>
    </FlexContainer>
  );
};

export default MyPage;

