import React, { useState } from 'react';
import styled from 'styled-components';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { FeedComment } from './Feed';
import SendIcon from '@mui/icons-material/Send';

const InteractionBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const LikeButton = styled.button`
  color: ${({ theme }) => theme.colors.darkPurple};
  border: none;
  background-color: transparent;
  font-family: Bangers, cursive;
  font-size: 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

const CommentForm = styled.form`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

const CommentInput = styled.input`
  flex-grow: 1;
  margin-right: 1rem;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.darkPurple};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 1rem;

  &::placeholder {
    color: ${({ theme }) => theme.colors.darkPurple};
  }

  &:focus {
    outline: none;
    border-bottom: 2px solid ${({ theme }) => theme.colors.darkPurple};
  }
`;

const TextButton = styled.button`
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.darkPurple};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 1rem;
  width: fit-content;

  &:hover {
    cursor: pointer;
  }
`;

const CommentButton = styled.button`
  border: none;
  background: transparent;
`;


interface InteractionBarProps {
  onLike: () => void;
  onComment: (comment: string) => void;
  isLiked?: boolean;
  likesCount: number;
  comments: FeedComment[];
}

const InteractionBar = ({
  onLike,
  onComment,
  isLiked,
  likesCount,
  comments,
}: InteractionBarProps) => {
  const [comment, setComment] = useState('');
  const [viewComments, setViewComments] = useState(false);

  const handleCommentSubmit = (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }
    if (comment.trim() === '') return;
    onComment(comment);
    setComment('');
  };

  return (
    <InteractionBarContainer>
      <LikeButton onClick={onLike}>
        {isLiked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        {likesCount}
      </LikeButton>
      <TextButton onClick={() => setViewComments(!viewComments)}>
      {!viewComments ? `View all ${comments.length} comments` : "Hide comments"}
      </TextButton>
      {viewComments &&
        comments.map((comment, i) => (
          <div key={i}>
            {comment.userId}
            <strong>{comment.userName}</strong> {comment.text}
          </div>
        ))}
      <CommentForm onSubmit={handleCommentSubmit}>
        <CommentInput
          placeholder="Add a comment..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <CommentButton type="submit">
          <SendIcon />
        </CommentButton>
      </CommentForm>
    </InteractionBarContainer>
  );
};

export default InteractionBar;