import React from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { raceDataState } from '../../Recoil/selectors';

const TimerWrapper = styled.div`
  font-size: 1.5em;
  color: ${({ theme }) => theme.colors.pink};
  font-family: ${({ theme }) => theme.fonts.primary};
  text-align: center;
`;

const renderer = ({
  hours,
  minutes,
  seconds,
  completed,
}: CountdownRenderProps) => {
  if (completed) {
    return (
      <TimerWrapper>
        {0}:{0}:{0}
      </TimerWrapper>
    );
  } else {
    return (
      <TimerWrapper>
        {hours}:{minutes}:{seconds}
      </TimerWrapper>
    );
  }
};

const TimerComponent = () => {
  const { gamePin } = useParams();
  const raceData = useRecoilValue(
    raceDataState(gamePin ?? ''),
  );
  if (!raceData) {
    return null;
  }

  if (!raceData.startedAt) {
    return <></>; // render a loading text while the duration is being fetched
  } else {
    return (
      <Countdown
        date={
          new Date(
            raceData.startedAt.toDate().getTime() +
              raceData.duration! * 60 * 1000,
          )
        }
        renderer={renderer}

        // TODO: Uncomment the next line once you are ready to navigate upon completion
        //onComplete={() => navigate(`/${gamePin}/results`)}
      />
    );
  }
};

export default TimerComponent;
