import {
  collection,
  DocumentData,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';

export const fetchRaceData = async (
  gamePin: string,
): Promise<(DocumentData & { id: string }) | undefined> => {
  if (gamePin.length !== 6){
    return;
  }
  const db = getFirestore();
  const racesRef = collection(db, 'races');
  const q = query(racesRef, where('gamePin', '==', gamePin));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    console.log('No matching documents.');
    return;
  }

  return {
    ...querySnapshot.docs[0].data(),
    id: querySnapshot.docs[0].id,
  } as DocumentData & { id: string };
};
