import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { selectorFamily } from 'recoil';
import { fetchRaceData } from '../FirestoreUtils/fetchRaceData';
import { RaceData, Team } from './types';
import { getAuth } from 'firebase/auth';

export const raceDataState = selectorFamily<RaceData | null, string>({
  key: 'raceDataState',
  get:
    (gamePin) =>
    async ({ get }) => {
      if (!gamePin) return null;

      const fetchedData = await fetchRaceData(gamePin);
      return fetchedData as RaceData | null;
    },
});

export const teamState = selectorFamily<Team | null | undefined, string>({
  key: 'teamState',
  get:
    (raceId) =>
    async ({ get }) => {
      try {
        const db = getFirestore();
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user || !raceId) {
          return null;
        }

        const teamsRef = collection(db, `races/${raceId}/teams`);
        const teamsSnapshot = await getDocs(teamsRef);
        const teams: Team[] = [];
        teamsSnapshot.forEach((doc) => {
          const teamData = doc.data() as Team;
          teams.push({
            ...teamData,
          });
        });
        // Find the team that includes the user
        const userTeam = teams.find((team) => team.members.includes(user.uid));
        return userTeam || null;
      } catch (error) {
        console.error('Error getting teams: ', error);
      }
    },
});
