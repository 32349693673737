import { TaskData } from '../Task';

export const BursdagTasks: TaskData[] = [
  {
    id: 1,
    title: 'Bursdagssangen',
    description: 'Syng "Bursdagssangen" på et offentlig sted.',
    points: 20,
  },
  {
    id: 2,
    title: 'Bartender',
    description: 'Lag en egen bursdagscocktail og server den til gjestene.',
    points: 15,
  },
  {
    id: 3,
    title: 'Gjemsel',
    description: 'Arranger en runde med gjemsel.',
    points: 10,
  },
  {
    id: 4,
    title: 'Karaoke-Kongen',
    description: 'Syng en sang på karaoke.',
    points: 20,
  },
  {
    id: 5,
    title: 'Dance-Off',
    description: 'Utfordre en venn til en dance-off.',
    points: 30,
  },
  {
    id: 6,
    title: 'Vitseduell',
    description: 'Delta i en vitseduell - den som får flest til å le, vinner.',
    points: 15,
  },
  {
    id: 7,
    title: 'Skuespilleren',
    description:
      'Gjør en improvisert fremføring av en scene fra din favorittfilm.',
    points: 25,
  },
  {
    id: 8,
    title: 'Bursdagsselfie',
    description: 'Ta en selfie med alle gjestene.',
    points: 10,
  },
  {
    id: 9,
    title: 'Skattejakt',
    description: 'Arranger en liten skattejakt rundt i huset/leiligheten.',
    points: 20,
  },
  {
    id: 10,
    title: 'Drikkelek',
    description: 'Start en drikkelek.',
    points: 15,
  },
  {
    id: 11,
    title: 'Toastmaster',
    description: 'Hold en takketale for alle gjestene.',
    points: 20,
  },
  {
    id: 12,
    title: 'Dyremaskerade',
    description:
      'Utfør en handling mens du imiterer et dyr - de andre må gjette hvilket dyr du etterligner.',
    points: 15,
  },
  {
    id: 13,
    title: 'Bursdagsdikt',
    description: 'Skriv et kort dikt om bursdag.',
    points: 15,
  },
  {
    id: 14,
    title: 'Kjendisimitasjon',
    description: 'Imiter din favorittkjendis.',
    points: 15,
  },
  {
    id: 15,
    title: 'Bytte Roller',
    description:
      'Bytt roller med en venn for en time - imiter deres måte å snakke og handle på.',
    points: 25,
  },
  {
    id: 16,
    title: 'Bursdagskroner',
    description: 'Bruk en hjemmelaget bursdagskrone i en time.',
    points: 10,
  },
  {
    id: 17,
    title: 'Stand-up',
    description: 'Gjør et kort stand-up sett for gjestene.',
    points: 30,
  },
  {
    id: 18,
    title: 'Fest Outfit',
    description: 'Bytt til et festantrekk valgt ut av gjestene.',
    points: 20,
  },
  {
    id: 19,
    title: 'Dyrekjeks',
    description: 'Spis 10 dyrekjeks i én munnfull.',
    points: 15,
  },
  {
    id: 20,
    title: 'Musikkquiz',
    description: 'Arranger en kort musikkquiz for gjestene.',
    points: 20,
  },
  {
    id: 21,
    title: 'Øyeblikksdans',
    description: 'Dans når en bestemt sang spilles.',
    points: 15,
  },
  {
    id: 22,
    title: 'Mimelek',
    description:
      'Mime en kjent person eller en handling, og se hvem som gjetter riktig.',
    points: 15,
  },
  {
    id: 23,
    title: 'Utfordringen',
    description: 'Utfordre en venn til armdrukking.',
    points: 20,
  },
  {
    id: 24,
    title: 'Bursdagsdekorasjoner',
    description: 'Lag din egen bursdagsdekorasjon.',
    points: 20,
  },
  {
    id: 25,
    title: 'Matlaging',
    description: 'Lag en bursdagsmiddag for deg selv.',
    points: 30,
  },
];
