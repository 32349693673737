import { TaskData } from '../Task';

export const BedriftTasks: TaskData[] = [
  {
    id: 1,
    title: 'Sky-Høy Snap',
    description: 'Ta et bilde på toppen av det høyeste bygget i Oslo.',
    points: 20,
  },
  {
    id: 2,
    title: 'Budsjettvennlig Øl-Jakt',
    description:
      'Finn den billigste ølen i Oslo og ta et bilde av kvitteringen.',
    points: 15,
  },
  {
    id: 3,
    title: 'Iskrem-Tårn',
    description: 'Spis den høyeste iskremen du kan finne.',
    points: 10,
  },
  {
    id: 4,
    title: 'Dykkeren',
    description: 'Gå for et dykk.',
    points: 30,
  },
  {
    id: 5,
    title: 'Influencer',
    description: 'Spør på en kaffebar om noe gratis siden du er en influencer.',
    points: 30,
  },
  {
    id: 6,
    title: 'Snapshot Diversitet',
    description:
      "Ta et bilde med ansatte ved så mange forskjellige McDonald's/Backstube-filialer som mulig.",
    points: 30,
  },
  {
    id: 7,
    title: 'Strava Artist',
    description: 'Lag den beste Strava figuren du kan. Skjermbilde for bevis.',
    points: 20,
  },
  {
    id: 8,
    title: 'Lur Turist',
    description: 'Spør på Nasjonalmuseet hvor Munch-museet er.',
    points: 15,
  },
  {
    id: 9,
    title: "Dags Otto's Utfordring",
    description: 'Hei på minst 5 syklister i Tour de Finance.',
    points: 15,
  },
  {
    id: 10,
    title: 'Wi-Fi Vandrer',
    description:
      'Finn og koble til det offentlige WiFi-nettverket med det mest uvanlige navnet. Skjermbilde for bevis.',
    points: 15,
  },
  {
    id: 11,
    title: 'Historisk Jakt',
    description:
      'Finn og ta et bilde av den eldste gjenstanden du kan finne i Oslo.',
    points: 20,
  },
  {
    id: 12,
    title: 'Kunstjakten',
    description:
      'Finn det rareste stykket offentlig kunst i byen og ta en gruppeselfie med det.',
    points: 15,
  },
  {
    id: 13,
    title: 'Transport Trivia',
    description:
      'Bruk alle former for offentlig transport tilgjengelig i Oslo (Buss, trikk, t-bane, ferge osv.) Dokumenter dette med bilder eller billetter.',
    points: 30,
  },
  {
    id: 14,
    title: 'Øko-Utfordring',
    description:
      'Plukk opp og kast minst ti biter søppel i et offentlig område.',
    points: 10,
  },
  {
    id: 15,
    title: 'Opera Husets Originalitet',
    description:
      'Framfør en kort, morsom sang eller sketsj på de hvite marmortrappene til Oslo Operahus og ta det opp.',
    points: 25,
  },
  {
    id: 16,
    title: 'Lottomillionær',
    description: 'Vinn på pantemaskinen.',
    points: 15,
  },
  {
    id: 17,
    title: 'Dyreelsker',
    description: 'Klapp en hest.',
    points: 15,
  },
  {
    id: 18,
    title: 'Starstruck',
    description: 'Få en autograf fra en kjendis.',
    points: 30,
  },
  {
    id: 19,
    title: 'Engasjert Ungdom',
    description: 'Organiser en protest foran Stortinget med ditt eget slagord.',
    points: 30,
  },
  {
    id: 20,
    title: 'Luftrotte',
    description: 'Mat en måke.',
    points: 15,
  },
  {
    id: 21,
    title: 'Skipper',
    description: 'Vær ombord på en båt.',
    points: 15,
  },
  {
    id: 22,
    title: 'Rask Smak av Sommer',
    description: 'Spis en 10-pakning med lollipop så fort du kan.',
    points: 20,
  },
  {
    id: 23,
    title: 'Fast Kunde',
    description:
      'Gå til Narvesen/7-Eleven og bestill "det vanlige". Insister på at det er det du alltid kjøper og ta et bilde av varen.',
    points: 15,
  },
  {
    id: 24,
    title: 'The Champion',
    description: 'Spill stein, saks, papir med en fremmed - vinn best av 3.',
    points: 10,
  },
  {
    id: 25,
    title: 'Byens Beste Kaffe',
    description:
      'Sammenlign minst 2 kaffer kjøpt fra sentralstasjonen og utnevn en vinner.',
    points: 10,
  },
  {
    id: 26,
    title: 'Intervalltrening',
    description: 'Løp opp rulletrappen feil vei.',
    points: 10,
  },
  {
    id: 27,
    title: 'Jacks bursdag',
    description:
      'Få noen turister til å synge en gratulasjonssang til Jack på deres morsmål. Ta opp en video.',
    points: 30,
  },
];

export const company: TaskData[] = [
  {
    id: 1,
    title: 'Sky-High Snap',
    description: 'Take a photo at the top of the highest building in Oslo.',
    points: 20,
  },
  {
    id: 2,
    title: 'Budget Brew Hunt',
    description:
      'Find the cheapest beer in Oslo and take a picture of the receipt.',
    points: 15,
  },
  {
    id: 3,
    title: 'Ice Cream Tower',
    description: 'Eat the tallest ice cream you can find.',
    points: 10,
  },
  {
    id: 4,
    title: 'Dive into Adventure',
    description: 'Go for a dive.',
    points: 30,
  },
  {
    id: 5,
    title: 'Influencer Imitation',
    description:
      'Ask at a coffee shop for something for free since you are an influencer.',
    points: 30,
  },
  {
    id: 6,
    title: 'Code Breaker',
    description:
      'Solve a simple coding challenge and submit a screenshot of your correct solution. This could be done on a website like HackerRank or Codecademy.',
    points: 15,
  },
  {
    id: 7,
    title: 'Snapshot Diversity',
    description:
      "Take a picture with staff members at as many different McDonald's/Backstube branches as possible.",
    points: 30,
  },
  {
    id: 8,
    title: 'Strava Artist',
    description:
      'Create the best Strava figure art you can. Screenshot for proof.',
    points: 20,
  },
  {
    id: 9,
    title: 'Tourist Trickster',
    description: 'Ask at the National Museum where the Munch Museum is.',
    points: 15,
  },
  {
    id: 10,
    title: "Day Otto's Challenge",
    description: 'Cheer on at least 5 cyclists on the Tour de Finance.',
    points: 15,
  },
  {
    id: 11,
    title: 'Wi-Fi Wanderers',
    description:
      'Find and connect to the public WiFi network with the most unusual name. Screenshot for proof.',
    points: 15,
  },
  {
    id: 12,
    title: 'Historical Hunt',
    description:
      'Find and take a photo at the oldest item you can find in Oslo.',
    points: 20,
  },
  {
    id: 13,
    title: 'Local Language',
    description:
      'Record a video of your team trying to speak Norwegian, asking for directions to a famous Oslo landmark.',
    points: 20,
  },
  {
    id: 14,
    title: 'Public Art Pursuit',
    description:
      'Find the weirdest piece of public art in the city and take a group selfie with it.',
    points: 15,
  },
  {
    id: 15,
    title: 'Transport Trivia',
    description:
      'Use every form of public transportation available in Oslo (Bus, tram, subway, ferry etc.) Document this with photos or tickets.',
    points: 30,
  },
  {
    id: 16,
    title: 'Eco-Challenge',
    description:
      'Pick up and dispose at least ten pieces of litter in a public area.',
    points: 10,
  },
  {
    id: 17,
    title: 'Opera House Originality',
    description:
      'Perform a short, fun song or skit on the marble white steps of the Oslo Opera House and record it.',
    points: 25,
  },
  {
    id: 18,
    title: 'Solar Sighting',
    description:
      'Find the building in Oslo that uses most solar panels and take a picture with the panels in the background.',
    points: 10,
  },
  {
    id: 19,
    title: 'Lottery Millionaire',
    description: 'Win at the bottle return machine.',
    points: 15,
  },
  {
    id: 20,
    title: 'Animal Lover',
    description: 'Pet a horse.',
    points: 15,
  },
  {
    id: 21,
    title: 'Starstruck',
    description: 'Get an autograph from a celebrity.',
    points: 30,
  },
  {
    id: 22,
    title: 'Engaged Youth',
    description:
      'Organize a protest in front of the parliament with your own slogan.',
    points: 30,
  },
  {
    id: 23,
    title: 'Air Rat',
    description: 'Feed a seagull.',
    points: 15,
  },
  {
    id: 24,
    title: 'Skipper',
    description: 'Be on a boat.',
    points: 15,
  },
  {
    id: 25,
    title: 'Taste of Summer',
    description: 'Eat a 10-pack lollipop as fast as you can.',
    points: 20,
  },
  {
    id: 26,
    title: 'Regular Customer',
    description:
      'Go to Narvesen/7-Eleven and order "the usual". Insist it\'s what you always buy and take a picture of the item.',
    points: 15,
  },
  {
    id: 27,
    title: 'Reigning Champion',
    description: 'Play Rock, Paper, Scissors with a stranger - win best of 3.',
    points: 10,
  },
  {
    id: 28,
    title: "Oslo's Best Coffee",
    description:
      'Compare at least 2 coffees bought from Oslo S and declare a winner.',
    points: 10,
  },
  {
    id: 29,
    title: 'Interval Training',
    description: 'Run up the down escalator 3 times.',
    points: 10,
  },
  {
    id: 32,
    title: "Jack's birthday",
    description:
      'Have some tourists sing Jack a Happy Birthday song in their native language. Record a video.',
    points: 30,
  },
];
