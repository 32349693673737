import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, IconButton, Collapse } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';
import Header from '../Components/Header';
import { styled } from '@mui/material/styles';
import LinkButton from '../Components/LinkButton';
import { useNavigate } from 'react-router-dom';

const CloseButton = styled(IconButton)(({}) => ({
  position: 'absolute',
  top: '0.5rem',
  right: '0.5rem',
}));

const ToggleButton = styled(IconButton)(({}) => ({
  marginLeft: 'auto',
}));

interface SectionProps {
  title: string;
  children: React.ReactNode;
  open: boolean;
  onToggle: () => void;
}

const Section = ({ title, children, open, onToggle }: SectionProps) => (
  <Box sx={{ mb: 2 }}>
    <Box display="flex" alignItems="center" onClick={onToggle} sx={{ cursor: 'pointer' }}>
      <Header level={4}>{title}</Header>
      <ToggleButton>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ToggleButton>
    </Box>
    <Collapse in={open}>
      <Box sx={{ mt: 1 }}>
        {children}
      </Box>
    </Collapse>
  </Box>
);

const InfoPopup = () => {
  const [open, setOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [contestantsOpen, setContestantsOpen] = useState(false);
  const [organizersOpen, setOrganizersOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const isFirstVisit = !localStorage.getItem('hasVisited');
    if (isFirstVisit) {
      setOpen(true);
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  return (
    <>
      <IconButton onClick={handleOpen} 
      sx={{
        position: 'absolute',
        top: '0.5rem',
        right: '0.5rem',
        color: '#301934',
      }}>
        <InfoIcon />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            maxWidth: '80%',
            maxHeight: '80%',
            bgcolor: '#F8F8F2',
            border: '2px solid #000',
            borderRadius: '4px',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
          }}
        >
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <Header level={2}>Remarkable Race</Header>
          <Section title="Om" open={aboutOpen} onToggle={() => setAboutOpen(!aboutOpen)}>
            <Typography variant="body1" paragraph>
              Remarkable Race er perfekt for firmafester, og byr på morsomme oppgaver og utfordringer over hele Oslo.
            </Typography>
          </Section>
          <Section title="For deltagere" open={contestantsOpen} onToggle={() => setContestantsOpen(!contestantsOpen)}>
            <Typography variant="body1" paragraph>
              Slik kommer du i gang som deltager:
            </Typography>
            <Box sx={{ ml: 2, mt: 1 }}>
              <Typography variant="body2" paragraph>
                <strong>Skriv inn brukernavnet og spillpinkoden:</strong><br />
                Velg et unikt brukernavn og skriv inn spillpinnen fra arrangøren.
              </Typography>
              <Typography variant="body2" paragraph>
                <strong>Logg inn med Google:</strong><br />
                Bruk Google-kontoen din til å logge på raskt og sikkert.
              </Typography>
              <Typography variant="body2" paragraph>
                <strong>Venterom:</strong><br />
                Når du er logget inn, vil du bli plassert i et venterom til spillet starter.
              </Typography>
              <Typography variant="body2" paragraph>
                <strong>Lagoppdrag:</strong><br />
                Når spillet begynner, blir du automatisk tildelt et lag. Arbeid sammen med lagkameratene dine for å fullføre oppgaver og tjene poeng.
              </Typography>
              <Typography variant="body2" paragraph>
                <strong>Fullfør oppgaver og last opp bevis:</strong><br />
                Velg mellom en rekke oppgaver med ulike poengverdier. Fullfør oppgaver og last opp bilder eller videoer som bevis. Innleveringene dine vises i feeden slik at alle kan se dem.
              </Typography>
              <Typography variant="body2" paragraph>
                <strong>Live resultattavle:</strong><br />
                Hold oversikt over lagets fremgang på live resultattavlen. Følg med når poengene dine summerer seg og streber etter å nå toppen!
              </Typography>
            </Box>
          </Section>
          <Section title="For arrangører" open={organizersOpen} onToggle={() => setOrganizersOpen(!organizersOpen)}>
            <Typography variant="body1" paragraph>
            Slik kommer du i gang som arrangør:
            </Typography>
            <Box sx={{ ml: 2, mt: 1 }}>
            <Typography variant="body2" paragraph>
                <strong>Opprett et race:</strong><br />
                Start med å opprette et race med en standard oppgavepakke som passer best for deg. 
              </Typography>
              <Typography variant="body2" paragraph>
                <strong>Tilpass oppgavene:</strong><br />
                Når du har opprettet et race, kan du tilpasse oppgavene til din bedrift under Mine Race.
              </Typography>
              <Typography variant="body2" paragraph>
                <strong>Gi ut game-pinen:</strong><br />
                Før racet skal starte, gir du ut game pinen til deltagerne. Pass på at alle får kommet seg inn i venterommet før du starter racet! 
                Det er ikke mulig å starte racet før det er nok deltagere til å fylle minst et lag.
              </Typography>
            </Box>
            <LinkButton
              type={'button'}
              onClick={() => {
                navigate('/my-page');
              }}
            >
              Til arrangørsiden
            </LinkButton>
          </Section>

          <Section title="Kontakt" open={contactOpen} onToggle={() => setContactOpen(!contactOpen)}>
            <Typography variant="body1" paragraph>
              Dersom du har tilbakemeldinger, funnet bugs eller har spørsmål, ikke nøl med å ta kontakt på 
              📧 inarb@protonmail.com.
            </Typography>
          </Section>
        </Box>
      </Modal>
    </>
  );
};

export default InfoPopup;