import { createContext, useContext, useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import { useParams } from 'react-router-dom';

export interface Team {
  id: string;
  name: string;
  members: string[];
  points: number;
  completedTasks: number[];
}

export const TeamContext = createContext<Team | null>(null);

export const TeamProvider = ({ children }: any) => {
  const [team, setTeam] = useState<Team | null>(null);
  const userName = localStorage.getItem('userName');
  const { gamePin } = useParams();

  useEffect(() => {
    let unsubscribe: any;

    if (userName && gamePin) {
      const fetchUserTeam = async () => {
        const db = getFirestore();
        const racesRef = collection(db, 'races');
        const q = query(racesRef, where('gamePin', '==', gamePin));

        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          console.error('No matching documents.');
          return;
        }

        const raceData = querySnapshot.docs[0].data();

        if (raceData) {
          const teams: Team[] = raceData.teams || [];
          let userTeam: Team | null = null;

          for (let i = 0; i < teams.length; i++) {
            if (teams[i].members.includes(userName)) {
              userTeam = {
                id: String(i + 1),
                name: teams[i].name,
                members: teams[i].members,
                points: teams[i].points,
                completedTasks: teams[i].completedTasks,
              };
              break;
            }
          }

          setTeam(userTeam);
        }
      };

      fetchUserTeam(); // Moved this line outside the function definition
    } else {
      setTeam(null);
    }

    // Clean up the subscription on unmount
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [userName]);

  return <TeamContext.Provider value={team}>{children}</TeamContext.Provider>;
};

export const useTeam = () => useContext(TeamContext);
