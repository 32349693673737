import React from 'react';
import './App.css';
import styled, { ThemeProvider } from 'styled-components';
import { UserProvider } from './Contexts/UserProvider';
import { TeamProvider } from './Contexts/TeamProvider';
import FrontPage from './FrontPageView/FrontPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CreateRace from './MyPageView/CreateRaceView/CreateRaceForm';
import Leaderboard from './LeaderboardView/Leaderboard';
import Feed from './FeedView/Feed';
import TaskList from './TaskView/TaskList';
import MyPage from './MyPageView/MyPageView';
import { theme } from './theme';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './Translations/en.json';
import no from './Translations/no.json';
import WaitingRoomView from './WaitingRoomView/WaitingRoomView';
import ToggleView from './ToggleView';
import { RecoilRoot } from 'recoil';
import EditRace from './MyPageView/EditView/EditRace';
import SignIn from './Components/SignIn';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    no: { translation: no },
  },
  lng: 'no', // Set default language to Norwegian
  interpolation: {
    escapeValue: false,
  },
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #301934;
  width: 100vw;
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <Container>
          <UserProvider>
            <TeamProvider>
              <Router>
                <Routes>
                  <Route path="/" element={<FrontPage />} />
                  <Route path="/create" element={<CreateRace />} />
                  <Route path="/my-page" element={<MyPage />} />
                  <Route path="/edit-race/:raceId" element={<EditRace />} />
                  <Route path='/sign-in' element={<SignIn />} />
                  <Route
                    path="/waiting-room/:gamePin"
                    element={<WaitingRoomView />}
                  />
                  <Route path="/race/:gamePin/*" element={<ToggleView />}>
                    <Route path="leaderboard" element={<Leaderboard />} />
                    <Route path="feed" element={<Feed />} />
                    <Route path="tasks" element={<TaskList />} />{' '}
                    {/*<Route path="results" component={Results} />*/}
                  </Route>
                </Routes>
              </Router>
            </TeamProvider>
          </UserProvider>
        </Container>
      </RecoilRoot>
    </ThemeProvider>
  );
}

export default App;
