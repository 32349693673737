import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import Label from './Label';
import { useTranslation } from 'react-i18next';

interface SelectProps {
  options: string[] | number[];
  value: string | number;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  label: string;
  placeholder: string;
}

const StyledSelect = styled.select`
  background: ${(props) => props.theme.colors.offWhite};
  color: ${(props) => props.theme.colors.darkPurple};
  border: 2px solid ${(props) => props.theme.colors.darkPurple};
  padding: 10px 15px;
  margin-bottom: 30px;
  border-radius: 10px;
  outline: none;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 1.2rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  width: 100%;

  &:focus {
    border-color: ${(props) => props.theme.colors.teal};
    box-shadow: 0 0 10px ${(props) => props.theme.colors.teal};
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.darkPurple};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 1rem;
    margin-bottom: 25px;
  }
`;

const Select = ({
  options,
  value,
  onChange,
  label,
  placeholder,
}: SelectProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Label>{t(label)}</Label>

      <StyledSelect value={value} onChange={onChange}>
        <option value="">{t(placeholder)}</option>
        {options.map((pkg, index) => (
          <option key={index} value={pkg}>
            {pkg}
          </option>
        ))}
      </StyledSelect>
    </>
  );
};

export default Select;
