import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useState,
  useTransition,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Label from '../Components/Label';
import InputField from '../Components/InputField';
import Button from '../Components/Button';
import { FlexContainer } from '../Components/FlexContainer';
import ErrorText from '../Components/ErrorText';
import { useTranslation } from 'react-i18next';
import LinkButton from '../Components/LinkButton';
import Header from '../Components/Header';
import { useRecoilState } from 'recoil';
import { authState } from '../Recoil/atoms';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import InfoPopup from './InfoPopup';
import styled from 'styled-components';
import { fetchRaceData } from '../FirestoreUtils/fetchRaceData';
import SignIn from '../Components/SignIn';

const FrontPageDiv= styled.div`
  margin: auto auto;
`;

const FrontPage = () => {
  const [gamePin, setGamePin] = useState('');
  const [userName, setUserName] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [isPending, startTransition] = useTransition();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authFirebase = getAuth();
  const [auth, setAuth] = useRecoilState(authState);

  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authFirebase, (currentUser) => {
      setUser(currentUser);
      setAuth({ uid: currentUser?.uid ?? null });
    });

    return () => unsubscribe();
  }, [authFirebase, setAuth]);

  const handleGamePinChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    startTransition(() => {
      setGamePin(value);
    });
  };

  const handleUserNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUserName(value);
  };

  const handleSubmit = async (event: FormEvent) => {
    console.log("onSubmit");
    event.preventDefault();
    setLoading(true);

    const data = await fetchRaceData(gamePin);

    try {
      if (!data) {
        setErrorMessage('No race found with this game PIN!');
        return;
      }

      if (data.status === 'ongoing' || data.status === 'completed') {
        setErrorMessage('Already ongoing or finished!');
        return;
      }

      const participant = userName || `User${uuidv4()}`;
      localStorage.setItem('userName', participant);

      navigate(`/waiting-room/${gamePin}`);
    } catch (error) {
      console.error('Error signing in anonymously: ', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FrontPageDiv>
      <FlexContainer width={'75%'} alignItems='flex-start'>
        <InfoPopup />
        <Header level={1}>Remarkable Race</Header>
        <form onSubmit={handleSubmit}>
          <FlexContainer flexDirection={'row'}>
            <Label>
              {t('frontPage.enterName')}
              <InputField
                type="text"
                value={userName}
                onChange={handleUserNameChange}
              />
            </Label>
          </FlexContainer>
          <FlexContainer flexDirection={'row'}>
            <Label>
              {t('frontPage.enterGamePin')}
              <InputField
                type="text"
                value={gamePin}
                onChange={handleGamePinChange}
              />
            </Label>
          </FlexContainer>
          {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
          <Button type="submit" disabled={loading || isPending}>
            {'Join Game'}
          </Button>
        </form>

        <LinkButton
          type={'button'}
          onClick={() => {
            navigate('/my-page');
          }}
        >
          {t('frontPage.orToCreate')}
        </LinkButton>
      </FlexContainer>
    </FrontPageDiv>
  );
};

export default FrontPage;
