import React from 'react';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithCredential,
  signInWithPopup,
} from 'firebase/auth';
import styled from 'styled-components';
import GoogleIcon from '@mui/icons-material/Google';
import { useSetRecoilState } from 'recoil';
import { authState } from '../Recoil/atoms';
import { FirebaseError } from 'firebase/app';
import Header from './Header';
import Paragraph from './Paragraph';
import { FlexContainer } from './FlexContainer';

const SignInContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const GoogleSignInButton = styled.button`
  font-size: 1.2em;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #301934;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #357ae8;
  }

  svg {
    margin-right: 10px;
  }
`;

export async function signInWithGoogle() {
  const provider = new GoogleAuthProvider();
  const authFirebase = getAuth();
  let uid = null;

  try {
    const result = await signInWithPopup(authFirebase, provider);
    const credential = GoogleAuthProvider.credentialFromResult(result);

    if (credential) {
      const usercred = await signInWithCredential(authFirebase, credential);
      uid = usercred.user.uid;
    }
  } catch (error) {
    console.error(error);
  }

  return uid;
}

interface SignInProps {
  text?: string;
}

const SignIn = ({text}: SignInProps) => {
  const setAuth = useSetRecoilState(authState);

  const handleSignInWithGoogle = async () => {
    const uid = await signInWithGoogle();
    if (uid) {
      setAuth({ uid: uid });
    }
  };

  return (
    <FlexContainer>
      <Header level={5}>Sign in</Header>
      <Paragraph>{text}</Paragraph>
      <SignInContainer>
        <GoogleSignInButton onClick={handleSignInWithGoogle}>
          <GoogleIcon />
          Sign in with Google
        </GoogleSignInButton>
      </SignInContainer>
    </FlexContainer>

  );
};

export default SignIn;
