import React, { Suspense, useEffect, useState, useTransition } from 'react';
import Task from './Task';
import styled from 'styled-components';
import { Diversity1 } from '@mui/icons-material';
import TimerComponent from './Timer/Timer';
import { useRecoilValue } from 'recoil';
import { raceDataState, teamState } from '../Recoil/selectors';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../firebase';
import { TaskProps } from '../Recoil/types';
import { useParams } from 'react-router-dom';

const TeamBubble = styled.div`
  position: absolute;
  top: 60px;
  right: 5px;
  background-color: #f8f8f2;
  color: #000;
  font-size: 12px;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  width: 50px;
  height: 50px;
  font-family: Bangers, cursive;
  border: 2px solid #000000;
`;

const TaskListStyle = styled.div`
  width: 100%;
  max-width: 600px;
  margin: auto;
`;

const TaskList = () => {
  const [tasks, setTasks] = useState<TaskProps[]>([]);
  const [isPending, startTransition] = useTransition();
  const { gamePin } = useParams();
  const raceData = useRecoilValue(raceDataState(gamePin ?? ""));
  
  const userTeam = useRecoilValue(teamState(raceData?.id || ""));

  useEffect(() => {
    if (raceData?.id) {
      startTransition(() => {
        const fetchTasks = async () => {
          try {
            const q = query(collection(db, `races/${raceData.id}/tasks`));
            const querySnapshot = await getDocs(q);
            const tasks: TaskProps[] = querySnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            } as TaskProps));
            setTasks(tasks);
          } catch (error) {
            console.error("Error fetching tasks:", error);
          }
        };
        fetchTasks();
      });
    }
  }, [raceData]);

  if (!raceData) {
    return <div>Loading...</div>;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <TaskListStyle>
        <TeamBubble>
          <Diversity1 /> {userTeam?.name}
        </TeamBubble>
        <TimerComponent />
        {tasks.map((task, index) => (
          <Task
            key={index}
            task={task}
            completed={userTeam?.completedTasks.includes(task.id) ?? false}
          />
        ))}
      </TaskListStyle>
    </Suspense>
  );
};

export default TaskList;
