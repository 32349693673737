import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import 'firebase/auth';
import { getAuth } from 'firebase/auth';
import { GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {

  apiKey: "AIzaSyB6_G6nggNBBjx2nj32d0Cdb7_8r-8ZYgg",

  authDomain: "enode-amazing-race.firebaseapp.com",

  projectId: "enode-amazing-race",

  storageBucket: "enode-amazing-race.appspot.com",

  messagingSenderId: "628678558080",

  appId: "1:628678558080:web:ed816a0c22e5e49d7e8165"

};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
// Initialize Firebase Authentication
export const auth = getAuth();

// Initialize Google Auth Provider
export const provider = new GoogleAuthProvider();
