import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  increment,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import React, { useState } from 'react';
import { db } from '../../firebase';
import styled from 'styled-components';
import { ArrowBack } from '@mui/icons-material';
import Paragraph from '../../Components/Paragraph';
import { theme } from '../../theme';
import { useRecoilValue } from 'recoil';
import { raceDataState, teamState } from '../../Recoil/selectors';
import { RaceData } from '../../Recoil/types';
import Button from '../../Components/Button';
import Header from '../../Components/Header';
import imageCompression from 'browser-image-compression';
import { useParams } from 'react-router-dom';

const storage = getStorage();

const uploadMedia = async (
  file: File,
  raceId: string,
  taskId: string,
  teamId: string,
) => {
  const storageRef = ref(getStorage());
  const fileName = `${raceId}/${teamId}/${taskId}/${file.name}`;
  const fileRef = ref(storageRef, fileName);

  // Upload the file
  const snapshot = await uploadBytesResumable(fileRef, file);

  // Get the download URL
  const url = await getDownloadURL(snapshot.ref);

  return url;
};

const Container = styled.div`
  margin: 18px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.label`
  font-size: 18px;
  color: ${theme.colors.darkPurple};
  font-family: ${theme.fonts.primary};
  margin-bottom: 1em;
`;

const Input = styled.input`
  margin: 10px 0;
  padding: 10px;
  width: 84%;
  border: none;
  border-radius: 5px;
  color: ${theme.colors.darkPurple};
  background-color: ${theme.colors.offWhite};
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  font-size: 1em;
  transition: all 0.3s ease-in-out;
  font-family: 'Arial', sans-serif;

  &:focus {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  }
`;

const BackButton = styled.button`
  border: none;
  background-color: inherit;
  color: ${theme.colors.darkPurple};
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
`;

interface UploadMediaProps {
  taskId: string;
  points: number;
  setShowUpload: (val: boolean) => void;
  description: string;
}

const UploadMedia = ({
  taskId,
  points,
  setShowUpload,
  description,
}: UploadMediaProps) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<any>();
  const { gamePin } = useParams();

  if (!gamePin) {
    return null;
  }

  const raceData = useRecoilValue<RaceData | null>(raceDataState(gamePin));

  if (!raceData) {
    return null;
  }

  const userTeam = useRecoilValue(teamState(raceData.id));

  async function handleImageUpload(event: any) {
    if (!event.target.files) {
      return;
    }

    setLoading(true);

    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      setFile(compressedFile);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const handleUploadToFirebase = async () => {
    setLoading(true);

    if (!raceData?.id || !userTeam?.id) {
      throw Error('Missing race id or user id');
    }

    const raceId = raceData?.id;
    const url = await uploadMedia(file, raceId, taskId, userTeam.id);

    const teamRef = doc(db, `races/${raceId}/teams`, userTeam.id);

    const taskRef = doc(db, `races/${raceId}/tasks`, taskId);

    await updateDoc(taskRef, {
      proofs: arrayUnion({
        url,
        teamId: userTeam.id,
        timestamp: Timestamp.fromDate(new Date()),
      }),
    });

    await updateDoc(teamRef, {
      points: increment(points || 0),
      completedTasks: arrayUnion(taskId.toString()),
    });

    setFile(null);
    setLoading(false);
  };

  return (
    <Container>
      <BackButton
        onClick={() => {
          setTimeout(() => setShowUpload(false), 0);
        }}
      >
        <ArrowBack style={{ fontSize: '30px' }} />{' '}
      </BackButton>
      <>
        <Paragraph>{description}</Paragraph>
        <Label>Submit your task here</Label>
        <Input
          id="media-upload-input"
          type="file"
          accept=".png, .jpg, .jpeg, .gif, video/*"
          onChange={handleImageUpload}
          disabled={loading}
        />{' '}
      </>
      {loading && <Header level={6}> Uploading... </Header>}
      {file && userTeam && (
        <Button disabled={loading} onClick={handleUploadToFirebase}>
          Upload
        </Button>
      )}
    </Container>
  );
};

export default UploadMedia;
