import React, { ChangeEvent, FormEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  setDoc,
} from 'firebase/firestore';
import { UserContext } from '../../Contexts/UserProvider';
import SignIn from '../../Components/SignIn';
import Button from '../../Components/Button';
import { FlexContainer } from '../../Components/FlexContainer';
import { useTranslation } from 'react-i18next';
import Select from '../../Components/Select';
import InputField from '../../Components/InputField';
import Label from '../../Components/Label';
import ErrorText from '../../Components/ErrorText';
import Header from '../../Components/Header';
import { TaskData } from '../../TaskView/Task';
import { BedriftTasks } from '../../TaskView/data/company';
import { BursdagTasks } from '../../TaskView/data/birthday';
import Package from './Package';
import styled from 'styled-components';
import { theme } from '../../theme';

const taskPackages: Record<string, TaskData[]> = {
  bursdag: BursdagTasks,
  bedrift: BedriftTasks,
};

const StyledParagraph = styled.p`
  font-family: ${theme.fonts.secondary};
  color: ${theme.colors.darkPurple};
  font-size: 1em;
  margin-bottom: 2em;
  width: 90%;
  max-width: 600px;
`;

function CreateRace() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const [raceName, setRaceName] = useState<string>('');
  const [gamePin, setGamePin] = useState<string>('');
  const [selectedPackage, setSelectedPackage] = useState<string>('');
  const [selectedDuration, setSelectedDuration] = useState<number>(120);
  const [selectedTeamSize, setSelectedTeamSize] = useState<number>(4);
  const [startDate, setStartDate] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const packages = [
    {
      name: 'Bedrift',
      description:
        'Morsom oppgavepakke tilpasset bedrifter. Passer for eksempel til sommerfest, sommerjobb-event eller som en del av onboardingen til nyansatte.',
    },
  ];

  const teamSizes = [1, 2, 3, 4, 5, 6];

  const durations = [
    { label: t('duration.oneHour'), value: 60 },
    { label: t('duration.oneAndAHalfHour'), value: 90 },
    { label: t('duration.twoHours'), value: 120 },
    { label: t('duration.threeHours'), value: 180 },
  ];

  const handleRaceNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRaceName(event.target.value);
  };

  const handlePackageChange = (packageName: string) => {
    setSelectedPackage(packageName);
  };

  const handleDurationChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selected = durations.find(
      (duration) => duration.label === event.target.value,
    );
    setSelectedDuration(selected ? selected.value : 120);
  };

  const durationLabel = (value: number): string => {
    return durations.find((duration) => duration.value === value)?.label || '';
  };

  const handleTeamSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedTeamSize(event.target.value as unknown as number);
  };

  const handleStartDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value);
  };

  const generateGamePin = () => {
    // Generate a 6-digit PIN
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const db = getFirestore();
    const newGamePin = generateGamePin();

    if (
      !raceName ||
      !selectedPackage ||
      !selectedDuration ||
      !selectedTeamSize ||
      !startDate
    ) {
      setErrorMessage(t('createRace.errorMissingFields'));
      return;
    } else {
      setErrorMessage(null);
    }

    try {
      const raceDocRef = await addDoc(collection(db, 'races'), {
        name: raceName,
        gamePin: newGamePin,
        createdAt: new Date(),
        createdBy: user?.uid,
        status: 'pending',
        package: selectedPackage,
        duration: selectedDuration,
        teamSize: selectedTeamSize,
        startDate: new Date(startDate),
        participants: [],
      });

      // Get the tasks for the selected package
      const tasksForSelectedPackage =
        taskPackages[selectedPackage.toLowerCase()];

      const taskPromises = tasksForSelectedPackage.map((task: TaskData) => {
        return setDoc(doc(raceDocRef, 'tasks', task.id.toString()), {
          title: task.title,
          description: task.description,
          points: task.points,
          raceId: raceDocRef.id,
          isCompleted: false,
          proofs: [],
        });
      });

      await Promise.all(taskPromises);

      setGamePin(newGamePin);

      navigate('/my-page');
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  if (!user) {
    return <SignIn text={"Log in for å kunne lage og se races."}/>;
  }

  return (
    <FlexContainer width={'75%'}>
      <Header level={2}>Opprett et race</Header>
      <StyledParagraph> Opprett et nytt race. Du finner de igjen senere i oversikten, hvor du også kan endre, slette og legge til oppgaver. </StyledParagraph>
      <form onSubmit={handleSubmit}>
        <FlexContainer flexDirection={'column'} alignItems={'flex-start'}>
          <Label>Navn</Label>
          <InputField
            type="text"
            value={raceName}
            onChange={handleRaceNameChange}
          />
          <Select
            options={teamSizes}
            value={selectedTeamSize}
            onChange={handleTeamSizeChange}
            label={t('selectTeamSize.label')}
            placeholder={t('selectTeamSize.placeholder')}
          />
          <Select
            options={durations.map((duration) => duration.label)}
            value={durationLabel(selectedDuration)}
            onChange={handleDurationChange}
            label={t('selectDuration.label')}
            placeholder={t('selectDuration.placeholder')}
          />

          <Label>{t('selectPackage.label')}</Label>
          {packages.map((packageData) => (
            <Package
              key={packageData.name}
              name={packageData.name}
              description={packageData.description}
              selected={selectedPackage}
              onSelect={handlePackageChange}
            />
          ))}
          <Label>Planlagt start</Label>
          <InputField
            type="datetime-local"
            value={startDate}
            onChange={handleStartDateChange}
          />
        </FlexContainer>
        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        <Button type="submit">Oprett</Button>
      </form>
    </FlexContainer>
  );
}

export default CreateRace;