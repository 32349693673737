import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Check } from '@mui/icons-material';
import UploadMedia from './UploadMedia/UploadMedia';
import { FlexContainer } from '../Components/FlexContainer';
import Header from '../Components/Header';
import { TaskProps } from '../Recoil/types';
import { doc, onSnapshot } from 'firebase/firestore';
import { useRecoilValue } from 'recoil';
import { raceDataState, teamState } from '../Recoil/selectors';
import { db } from '../firebase';
import { useParams } from 'react-router-dom';

export type TaskData = {
  id: number;
  title: string;
  description: string;
  points: number;
};

type TaskPropsIn = { task: TaskProps; completed: boolean };

const TaskWrapper = styled.div<{ completed: boolean }>`
  border: 2px solid #000000;
  border-radius: 10px;
  margin: 20px 10px;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.offWhite};
  box-shadow: 2px 2px 2px #888888;
  position: relative;

  &:hover {
    box-shadow: 3px 3px 3px #888888;
  }

  color: ${(props) => (props.completed ? '#103000' : 'inherit')};
`;

const PointsBubble = styled.div<{ completed: boolean }>`
  position: absolute;
  border: 2px solid #000000;
  top: 5%;
  right: 10px;
  background-color: ${(props) => (props.completed ? '#1FCE6D' : '#FF6B6B')};
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  width: 30px;
  height: 30px;
`;

const Task = ({ task }: TaskPropsIn) => {
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);

  const { gamePin } = useParams();
  const raceData = useRecoilValue(raceDataState(gamePin ?? ""));

  const userTeam = useRecoilValue(teamState(raceData?.id ?? ''));

  useEffect(() => {
    if (!raceData?.id || !userTeam?.id) {
      return;
    }

    const teamRef = doc(db, `races/${raceData.id}/teams`, userTeam.id);

    const unsubscribe = onSnapshot(teamRef, (doc) => {
      if (
        doc.exists() &&
        doc.data()?.completedTasks.includes(task.id.toString())
      ) {
        setCompleted(true);
        setShowUpload(false);
      }
    });

    return () => unsubscribe();
  }, [raceData, userTeam, task]);

  return (
    <TaskWrapper
      completed={completed}
      onClick={() => {
        setShowUpload(true);
      }}
    >
      {showUpload && !completed ? (
        <UploadMedia
          taskId={task.id}
          points={task.points}
          setShowUpload={setShowUpload}
          description={task.description}
        />
      ) : (
        <FlexContainer>
          <Header level={6}>{task.title}</Header>
          <PointsBubble completed={completed}>
            {completed ? <Check /> : task.points}{' '}
          </PointsBubble>
        </FlexContainer>
      )}
    </TaskWrapper>
  );
};

export default Task;
